import { config } from "../env";
import {
  Delete,
  get,
  patch,
  post,
  postFileUpload,
} from "./helpers/http-handler";

// upload
export const adminImportCountriesListManagement = (payload: object) =>
  postFileUpload(
    `${config.API_URL}/location-management/import/countries`,
    payload
  );

export const adminImportStatesListManagement = (payload: object) =>
  postFileUpload(
    `${config.API_URL}/location-management/import/states`,
    payload
  );

export const adminImportCitiesListManagement = (payload: object) =>
  postFileUpload(
    `${config.API_URL}/location-management/import/cities`,
    payload
  );

// get
export const adminGetCountriesListManagement = () =>
  get(`${config.API_URL}/location-management/list/countries`);

export const adminGetStatesListManagement = (countryId: number) =>
  get(
    `${config.API_URL}/location-management/list/states?countryId=${countryId}`
  );

export const adminGetCitiesListManagement = (stateId: number) =>
  get(`${config.API_URL}/location-management/list/cities?stateId=${stateId}`);

export const adminGetListManagementGroupCode = (groupCode: string) =>
  get(`${config.API_URL}/list-management/list/${groupCode}`);

// attach category to state
export const adminGetListManagementStateCategoryList = (stateId: string) =>
  // get(
  // 	`${config.API_URL}/legislation-management/state-category-of-law?state_id=${stateId}`
  // );
  get(`${config.API_URL}/state-category-of-law?state_id=${stateId}`);

export const adminCreateListManagementStateCategoryList = (payload: object) =>
  post(
    `${config.API_URL}/legislation-management/state-category-of-law`,
    payload
  );

export const adminCreateListManagementStateCategoryListNew = (
  payload: object
) => post(`${config.API_URL}/state-category-of-law`, payload);

// group

export const adminListManagementCreateGroup = (payload: object) =>
  post(`${config.API_URL}/list-management/groups`, payload);

export const adminListManagementGetGroups = () =>
  get(`${config.API_URL}/list-management/groups`);

export const adminListManagementPatchGroup = (id: string, payload: object) =>
  patch(`${config.API_URL}/list-management/groups/${id}`, payload);

export const adminListManagementDeleteGroup = (id: string) =>
  Delete(`${config.API_URL}/list-management/groups/${id}`);

// group items

export const adminListManagementCreateGroupItems = (
  groupId: string,
  payload: object
) => post(`${config.API_URL}/list-management/groups/${groupId}/items`, payload);

export const adminListManagementGetGroupsItems = (groupId: string) =>
  get(`${config.API_URL}/list-management/groups/${groupId}/items`);

export const adminListManagementPatchGroupItems = (
  groupId: string,
  itemId: string,
  payload: object
) =>
  patch(
    `${config.API_URL}/list-management/groups/${groupId}/items/${itemId}`,
    payload
  );

export const adminListManagementDeleteGroupItems = (
  groupId: string,
  itemId: string
) =>
  Delete(`${config.API_URL}/list-management/groups/${groupId}/items/${itemId}`);

// legislation

export const adminCreateLegislationManagement = (payload: object) =>
  // post(`${config.API_URL}/legislation-management/legislation`, payload);
  post(`${config.API_URL}/legislation`, payload);

export const adminGetLegislationManagement = (stateId: string) =>
  // get(
  // 	`${config.API_URL}/legislation-management/legislation?state_id=${stateId}`
  // );
  get(`${config.API_URL}/legislation?state_id=${stateId}`);

export const adminGetSpecificLegislationManagement = (id: string) =>
  // get(`${config.API_URL}/legislation-management/legislation/${id}`);
  get(`${config.API_URL}/legislation/${id}`);

export const adminUpdateSpecificLegislationManagement = (
  id: string,
  payload: object
) =>
  // patch(`${config.API_URL}/legislation-management/legislation/${id}`, payload);
  patch(`${config.API_URL}/legislation/${id}`, payload);

export const adminDeleteSpecificLegislationManagement = (id: string) =>
  // Delete(`${config.API_URL}/legislation-management/legislation/${id}`);
  Delete(`${config.API_URL}/legislation/${id}`);

export const adminImportListManagement = (payload: object) =>
  postFileUpload(`${config.API_URL}/legislation/import/data`, payload);


// legislation-rule
export const adminImportListManagementLegislationRule = (payload: object) =>
postFileUpload(`${config.API_URL}/legislation-rule/import/data`, payload);



export const adminCreateLegislationManagementRule = (payload: object) =>
  // post(`${config.API_URL}/legislation-management/legislation-rule`, payload);
  post(`${config.API_URL}/legislation-rule`, payload);

export const adminGetLegislationManagementRule = (legislation_id: string) =>
  // get(
  // 	`${config.API_URL}/legislation-management/legislation-rule?legislation_id=${legislation_id}`
  // );
  get(`${config.API_URL}/legislation-rule?legislation_id=${legislation_id}`);

export const adminGetSpecificLegislationManagementRule = (id: string) =>
  // get(`${config.API_URL}/legislation-management/legislation-rule/${id}`);
  get(`${config.API_URL}/legislation-rule/${id}`);

export const adminUpdateSpecificLegislationManagementRule = (
  id: string,
  payload: object
) =>
  // patch(
  // 	`${config.API_URL}/legislation-management/legislation-rule/${id}`,
  // 	payload
  // );
  patch(`${config.API_URL}/legislation-rule/${id}`, payload);

export const adminDeleteSpecificLegislationManagementRule = (id: string) =>
  // Delete(`${config.API_URL}/legislation-management/legislation-rule/${id}`);
  Delete(`${config.API_URL}/legislation-rule/${id}`);

//smart filters updated APIs

export const getSmartFilterCategoryOfLawList = (queryRequest: string) =>
  get(`${config.API_URL}/state-category-of-law?${queryRequest}`);

export const getSmartFilterLegislationList = (queryRequest: string) =>
  get(`${config.API_URL}/legislation?${queryRequest}`);

export const getSmartFilterLegislationRuleList = (queryRequest: string) =>
  get(`${config.API_URL}/legislation-rule?${queryRequest}`);

export const getTemplatesList = (queryRequest: string) =>
	get(`${config.API_URL}/imports?${queryRequest}`);

export const getTemplates = (queryRequest: string) =>
  get(`${config.API_URL}/templates?${queryRequest}`);

//custom field APIs

export const adminLegislationList = (queryRequest: string) =>
  get(`${config.API_URL}/legislation?${queryRequest}`);

export const adminLegislationRuleList = (queryRequest: string) =>
  get(`${config.API_URL}/legislation-rule?${queryRequest}`);

export const adminCategoryLawList = (queryRequest: string) =>
  get(`${config.API_URL}/state-category-of-law?${queryRequest}`);

export const adminListManagementList = (queryRequest: string) =>
  get(`${config.API_URL}/list-management/list-item?${queryRequest}`);

export const exportLegislation = (queryRequest: string) =>
  get(`${config.API_URL}/legislation/export/data?${queryRequest}`);


export const getExportReports = (queryRequest: string) =>
	get(`${config.API_URL}/reports?${queryRequest}`);
