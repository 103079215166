import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import qs from "query-string";
import { getValue, setValue } from "@utils/lodash";
//import { adminGetUsers } from '@services/users.service';
import { NavLink } from "react-router-dom";
//import moment from 'moment';
import {
  adminDeleteSpecificCheckpoint,
  adminExportPoint,
  adminGetCheckpoint,
  adminImportCheckpoint,
  adminUpdateBulkStatusCheckpoint,
} from "@services/checkpoint.service";
import { useNavigate } from "react-router-dom";
import FilterForm from "@components/common/AddFilter/FilterForm";
import NavigationBar from "@components/NotificationBar/index";
import DeleteModal from "@_common/deleteModal";
import { toast } from "react-toastify";
//import { Button } from 'reactstrap';
import DataTable from "@components/DataTable/index";
import PaginationPage from "@components/pagination/pagination";
import SearchHeader from "@components/common/SearchHeader";
import {
  adminGetCountriesListManagement,
  adminGetLegislationManagement,
  adminGetLegislationManagementRule,
  adminGetListManagementGroupCode,
  adminGetListManagementStateCategoryList,
  adminGetStatesListManagement,
  getSmartFilterCategoryOfLawList,
  getSmartFilterLegislationList,
  getSmartFilterLegislationRuleList,
} from "@services/list-management.service";
import Notfound from "@components/Not-Found";
import Loader from "@components/common/Loader";
import { handleDownloadOnClick } from "@_common/downloadfile";
import NoAccessPage from "@components/common/NoAccess";
import { MigrationToClient } from "@services/auth.service";
import { QueryRequestHelper } from "@_common/query-request-helper";
import ScrollableReactTable from "@components/ScrollableDatatable";
import ScrollableDataTable from "@components/ScrollableDatatable/scrollableTable";
import { getSpecificCategoryLawById } from "@_common/global-apis";

interface ICheckpointActiveProps {}

const CheckpointActive: React.FunctionComponent<ICheckpointActiveProps> = (
  props: any
) => {
  let navigate = useNavigate();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [list, setList] = React.useState<[]>([]);
  const [isFilterVisible, setFilterVisibility] = React.useState(false);
  const [dropdownOptions] = useState([
    { value: "CHECKPOINT", label: "Checkpoint" },
  ]);


  const [dropdownOptionsCheckpoint] = useState([
    { value: "CHECKPOINT", label: "Checkpoint" },
    { value: "EXPORT_CHECKPOINT", label: "Export Checkpoint" },

  ]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
      getCountriesList();
      getSmartFilterList();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
      getCountriesList();
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      if (params.search_text) {
        setSearchInput(params.search_text);
        setShowSearchTextbox(true);
      }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
      setRequest({
        country_id: getValue(params, `country_id`, ""),
        state_id: getValue(params, `state_id`, ""),
        category_of_law_id: getValue(params, `category_of_law_id`, ""),
        legislation_id: getValue(params, `legislation_id`, ""),
        legislation_rule_id: getValue(params, `legislation_rule_id`, ""),
      });
      if (getValue(params, `country_id`, "")) {
        setFilterVisibility(true);
        getStatesList(getValue(params, `country_id`, ""));
      }
      if (
        getValue(params, `state_id`, "") ||
        getValue(params, `category_of_law_id`, "") ||
        getValue(params, `legislation_id`, "")
      ) {
        getCategoryLawList(
          "",
          getValue(params, `state_id`, ""),
          getValue(params, `category_of_law_id`, "")
        );
        getLegislationList(
          "",
          getValue(params, `state_id`, ""),
          getValue(params, `legislation_id`, "")
        );
        getLegislationRuleList(
          "",
          getValue(params, `legislation_id`, ""),
          getValue(params, `legislation_rule_id`, "")
        );
      }
      if (getValue(params, `smart_filter_tags`, "")) {
        getSmartFilterList();
      } else {
        getSmartFilterList();
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);
  const [smartFilterList, setSmartFilterList] = useState([]);
  const [selectedSmartFilterIds, setSelectedSmartFilterIds] = useState<any>([]);
  const handleChangeSelectMulti = (e: any) => {
    setSelectedSmartFilterIds(e);
  };
  const getSmartFilterList = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let selectedIDs: any = urlSearchParams.getAll("smart_filter_tags[]");

    try {
      let resp = await adminGetListManagementGroupCode("smart-filter");
      if (resp) {
        let obj = getValue(resp, `data.listItems`, []).map((item: object) => ({
          id: getValue(item, `id`, ""),
          is_active: getValue(item, `is_active`, ""),
          text: getValue(item, `text`, ""),
          value: getValue(item, `text`, ""),
          label: getValue(item, `text`, ""),
        }));
        setSmartFilterList(obj);
        if (getValue(selectedIDs, `length`, 0) > 0) {
          let arr: [] | any = [];
          selectedIDs.map((item: object) => {
            let filtered = obj.filter((ob: any) => ob.id == item);
            if (getValue(filtered, `length`, 0) > 0) {
              arr.push(filtered[0]);
            }
          });
          setSelectedSmartFilterIds(arr);
        }
      } else {
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let selectedIDs: any = urlSearchParams.getAll("smart_filter_tags[]");

    let payload: any = {};

    payload = {
      page_no: params.page_no ? parseInt(params.page_no) : 1,
      page_size: params.limit ? parseInt(params.limit) : limit,
      search_text: params.search_text,
      status: "active",
      sort_by: params.sort_by,
      country_id: getValue(params, `country_id`, ""),
      state_id: getValue(params, `state_id`, ""),
      category_of_law_id: await getSpecificCategoryLawById(
        getValue(params, `state_id`, ""),
        getValue(params, `category_of_law_id`, "")
      ),
      legislation_id: getValue(params, `legislation_id`, ""),
      legislation_rule_id: getValue(params, `legislation_rule_id`, ""),
      smart_filter_tags: selectedIDs,
    };

    // removing null values
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    let queryRequest = qs.stringify(payload, {
      arrayFormat: "bracket",
      encode: false,
    });
    try {
      setIsLoading(true);
      let resp = await adminGetCheckpoint(queryRequest);
      // let resp = await adminGetUsers(queryRequest);
      if (resp) {
        setList(
          getValue(resp, `data.checkpoints`, []).map((item: object) => ({
            ...item,
            category_of_lawName: getValue(item, `category_of_law.text`, ""),
            compliance_typeName: getValue(item, `compliance_type.text`, ""),
            compliance_activity: getValue(item, `compliance_activity`, ""),
            frequencyName: getValue(item, `frequency.text`, ""),
            legislationName: getValue(item, `legislation.legislation_name`, ""),
            legislation_ruleName: getValue(
              item,
              `legislation_rule.rule_name`,
              ""
            ),
          }))
        );
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChangeActiveStatus = async () => {
    try {
      let resp = await adminUpdateBulkStatusCheckpoint({
        ids: selectedIDs,
        is_active: false,
      });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSelectedIDs([]);
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      // removing null values
      Object.keys(payload).forEach((key) => {
        if (!payload[key] || payload[key] === undefined) {
          delete payload[key];
        }
      });
      let queryRequest = qs.stringify(payload, {
        arrayFormat: "bracket",
        encode: false,
      });
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const handleChangeSearch = async (e: any) => {
    setSearchInput(e.target.value);

    let payload: any = {
      search_text: e.target.value,
    };
    // removing null values
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    let queryRequest = qs.stringify(payload, {
      arrayFormat: "bracket",
      encode: false,
    });
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  const [selectedIDs, setSelectedIDs] = useState<any>([]);
  const getTableState = (value: object) => {
    let selectedIndexList = Object.keys(
      getValue(value, `selectedRowIds`, {})
    ).map((item) => parseInt(item));
    let selectedIds =
      list.length > 0
        ? list.filter((_item: object, index: number) =>
            selectedIndexList.includes(index)
          )
        : [];
    if (selectedIds.length > 0) {
      setSelectedIDs(
        selectedIds.map((item: object) => getValue(item, `id`, ""))
      );
    } else {
      setSelectedIDs([]);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    try {
      let resp = await adminDeleteSpecificCheckpoint(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeSortBy = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.sort_by = value;
    props.history.push({
      pathname: window.location.pathname,
      query: params,
    });
  };

  const handleChangeStatus = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.status = value;
    props.history.push({
      pathname: window.location.pathname,
      query: params,
    });
  };
  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(100);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.page_no = page_no;
    Object.keys(params).forEach((key) => {
      if (!params[key] || params[key] === undefined) {
        delete params[key];
      }
    });
    let queryRequest = qs.stringify(params, {
      arrayFormat: "bracket",
      encode: false,
    });
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.limit = limit;
    Object.keys(params).forEach((key) => {
      if (!params[key] || params[key] === undefined) {
        delete params[key];
      }
    });
    let queryRequest = qs.stringify(params, {
      arrayFormat: "bracket",
      encode: false,
    });
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleEdit = (id: string) => {
    navigate(`/checkpoint/active-detail/${id}`);
  };
  const rowClick = (obj: object) => {
    navigate(`/checkpoint/active-detail/${getValue(obj, `original.id`, "")}`);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Checkpoint ID",
        accessor: "checkpoint_id",
        width: 250,
        Cell: ({ row }: any) => {
          return (
            <NavLink to={`/checkpoint/active-detail/${row.original.id}`}>
              {row.values.checkpoint_id}
            </NavLink>
          );
        },
      },
      {
        Header: "Compliance Type",
        accessor: "compliance_typeName",
        width: 250,
      },
      {
        Header: "Legislation",
        accessor: "legislationName",
        width: 250,
      },
      {
        Header: "Rule",
        accessor: "legislation_ruleName",
        width: 200,
      },
      {
        Header: "Compliance Activity",
        accessor: "compliance_activity",
        width: 350,
      },
      {
        Header: "Reference",
        accessor: "reference",
        width: 200,
      },
      {
        Header: "Frequency",
        accessor: "frequencyName",
        width: 250,
      },

      // {
      // 	Header: "Action",
      // 	width: 250,
      // 	Cell: ({ row }: any) => {
      // 		return (
      // 			<>
      // 				<Button
      // 					color="primary"
      // 					onClick={() => handleEdit(row.original.id)}
      // 				>
      // 					Edit
      // 				</Button>
      // 				&nbsp;&nbsp;&nbsp;
      // 				<Button
      // 					color="danger"
      // 					onClick={() =>
      // 						handleDeleteFun(row.original.id, row.values.checkpoint_id)
      // 					}
      // 				>
      // 					Delete
      // 				</Button>
      // 			</>
      // 		);
      // 	},
      // },
    ],
    []
  );

  /* -------------------------------------------------------------------------- */
  /*                                  Smart Filters                             */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState<any>({
    country_id: "101",
    state_id: "",
    category_of_law_id: "",
    legislation_id: "",
    legislation_rule_id: "",
  });
  useEffect(() => {
    getStatesList(request.country_id);
  }, [request.country_id]);

  const [countryList, setCountryList] = useState([]);
  const [states, setStates] = useState([]);

  const reset = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      page_no: params.page_no,
      search_text: getValue(params, `search_text`, ""),
      limit: getValue(params, `limit`, ""),
    };
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    let queryRequest = qs.stringify(payload, {
      arrayFormat: "bracket",
      encode: false,
    });
    navigate(`${window.location.pathname}?${queryRequest}`);
    getData();
    reset1();
  };
  const [resetInput, setResetInput] = useState(false);
  const reset1 = () => {
    setRequest({
      ...request,
      // country_id: "",
      state_id: "",
      category_of_law_id: "",
      legislation_id: "",
      legislation_rule_id: "",
    });
    setSelectedSmartFilterIds([]);
    setResetInput(true);
  };

  const getCountriesList = async () => {
    try {
      let resp = await adminGetCountriesListManagement();
      if (resp) {
        setCountryList(
          getValue(resp, `data.countries`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const getStatesList = async (countryId: number) => {
    if (countryId)
      try {
        let resp = await adminGetStatesListManagement(countryId);
        if (resp) {
          setStates(
            getValue(resp, `data.states`, []).map((item: object) => ({
              ...item,
              value: getValue(item, `name`, ""),
              label: getValue(item, `name`, ""),
            }))
          );
        } else {
        }
      } catch (error) {}
  };

  /* ---------------------------  Category Of Law  ----------------------------- */
  const [categoryOfLawList, setCategoryOfLawList] = useState([]);
  const [categoryLawLoading, setCategoryLawLoading] = useState(false);
  const getCategoryLawList = async (
    value: string,
    state_id: string,
    id: string
  ) => {
    try {
      setResetInput(false);
      setCategoryLawLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        state_id: state_id ? state_id : getValue(request, `state_id`, ""),
        id: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getSmartFilterCategoryOfLawList(queryRequest);
      if (resp) {
        setCategoryOfLawList(
          getValue(resp, `data.stateCategoryOfLaw`, []).map((item: object) => ({
            // ...item,
            // id: getValue(item, `category_of_law.id`, ""),
            id: getValue(item, `id`, ""),
            value: getValue(item, `category_of_law.text`, ""),
            label: getValue(item, `category_of_law.text`, ""),
          }))
        );
        setCategoryLawLoading(false);
      } else {
        setCategoryLawLoading(false);
      }
    } catch (error) {
      setCategoryLawLoading(false);
    }
    if (!value) {
      setValue(request, `category_of_law_id`, "");
    }
  };

  /* ---------------------------  Legislation  ----------------------------- */
  const [legislationList, setLegislationList] = useState([]);
  const [legislationLoading, setLegislationLoading] = useState(false);
  const getLegislationList = async (
    value: string,
    state_id: string,
    id: string
  ) => {
    try {
      setResetInput(false);
      setLegislationLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        state_id: state_id ? state_id : getValue(request, `state_id`, ""),
        id: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getSmartFilterLegislationList(queryRequest);
      if (resp) {
        setLegislationList(
          getValue(resp, `data.legislations`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `legislation_name`, ""),
            label: getValue(item, `legislation_name`, ""),
          }))
        );
        setLegislationLoading(false);
      } else {
        setLegislationLoading(false);
      }
    } catch (error) {
      setLegislationLoading(false);
    }
    if (!value) {
      setValue(request, `legislation_id`, "");
    }
  };

  /* ---------------------------  Legislation Rule  ----------------------------- */
  const [ruleList, setRuleList] = useState([]);
  const [legislationRuleLoading, setLegislationRuleLoading] = useState(false);
  const getLegislationRuleList = async (
    value: string,
    legislation_id: string,
    id: string
  ) => {
    try {
      setResetInput(false);
      setLegislationRuleLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        legislation_id: legislation_id
          ? legislation_id
          : getValue(request, `legislation_id`, ""),
        id: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getSmartFilterLegislationRuleList(queryRequest);
      if (resp) {
        setRuleList(
          getValue(resp, `data.legislationRules`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `rule_name`, ""),
            label: getValue(item, `rule_name`, ""),
          }))
        );
        setLegislationRuleLoading(false);
      } else {
        setLegislationRuleLoading(false);
      }
    } catch (error) {
      setLegislationRuleLoading(false);
    }
    if (!value) {
      setValue(request, `legislation_rule_id`, "");
    }
  };

  /* ---------------------------  Onchange Smart filter  ----------------------------- */

  const handleChangeSelect = (e: any, type: string) => {
    if (type === "country_id") {
      getStatesList(e.id);
      setStates([]);
      setCategoryOfLawList([]);
      setLegislationList([]);
      setRuleList([]);
      setRequest({
        ...request,
        country_id: e.id,
        state_id: "",
        category_of_law_id: "",
        legislation_id: "",
        legislation_rule_id: "",
      });
    }
    if (type === "state_id") {
      setRequest({
        ...request,
        state_id: getValue(e, `id`, "") ? e.id : "",
        category_of_law_id: "",
        legislation_id: "",
        legislation_rule_id: "",
      });
      getCategoryLawList("", e.id, "");
      getLegislationList("", e.id, "");
      setCategoryOfLawList([]);
      setLegislationList([]);
      setRuleList([]);
    }
    if (type === "category_of_law_id") {
      setRequest({
        ...request,
        category_of_law_id: getValue(e, `id`, "") ? e.id : "",
      });
    }
    if (type === "legislation_id") {
      getLegislationRuleList("", getValue(e, `id`, "") ? e.id : "", "");
      setRequest({
        ...request,
        legislation_id: getValue(e, `id`, "") ? e.id : "",
        legislation_rule_id: "",
      });
    }
    if (type === "legislation_rule_id") {
      setRequest({
        ...request,
        legislation_rule_id: getValue(e, `id`, "") ? e.id : "",
      });
    }
    if (type === "frequency_id") {
      setRequest({
        ...request,
        frequency_id: getValue(e, `id`, "") ? e.id : "",
      });
    }
    if (type === "checkpoint_id") {
      setRequest({
        ...request,
        checkpoint_id: getValue(e, `id`, "") ? e.id : "",
      });
    }
  };

  const handleSubmitSmartFilter = () => {
    let selectedSmartFilterIdsPayload = JSON.parse(
      JSON.stringify(selectedSmartFilterIds)
    );
    if (
      getValue(request, `country_id`, "")
      // getValue(request, `state_id`, '')
      // && getValue(request, `category_of_law_id`, "") &&
      // getValue(request, `legislation_id`, "") &&
      // getValue(request, `legislation_rule_id`, "")
    ) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      let payload: any = {
        page_no: params.page_no,
        country_id: getValue(request, `country_id`, ""),
        state_id: getValue(request, `state_id`, ""),
        category_of_law_id: getValue(request, `category_of_law_id`, ""),
        legislation_id: getValue(request, `legislation_id`, ""),
        legislation_rule_id: getValue(request, `legislation_rule_id`, ""),
        search_text: getValue(params, `search_text`, ""),
        limit: getValue(params, `limit`, ""),
        smart_filter_tags:
          selectedSmartFilterIdsPayload.length > 0
            ? selectedSmartFilterIdsPayload.map((item: object) =>
                getValue(item, `id`, "")
              )
            : [],
      };
      Object.keys(payload).forEach((key) => {
        if (!payload[key] || payload[key] === undefined) {
          delete payload[key];
        }
      });
      let queryRequest = qs.stringify(payload, {
        arrayFormat: "bracket",
        encode: false,
      });
      navigate(`${window.location.pathname}?${queryRequest}`);
    } else {
      toast.error("All fields are mandatory");
    }
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  let country_ID = countryList.filter(
    (item: object) =>
      getValue(item, `id`, "") == getValue(request, `country_id`, "")
  );
  let state_ID = states.filter(
    (item: object) =>
      getValue(item, `id`, "") == getValue(request, `state_id`, "")
  );
  let cat_ID = categoryOfLawList.filter(
    (item: object) =>
      getValue(item, `id`, "") == getValue(request, `category_of_law_id`, "")
  );
  let leg_ID = legislationList.filter(
    (item: object) =>
      getValue(item, `id`, "") == getValue(request, `legislation_id`, "")
  );
  let rule_ID = ruleList.filter(
    (item: object) =>
      getValue(item, `id`, "") == getValue(request, `legislation_rule_id`, "")
  );

  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };

  const handleCheckpointExport = async () => {
    try {
      let resp = await adminExportPoint("");
      if (resp) {
        toast.success(getValue(resp, `message`,""));
        navigate(`/export-file-logs/EXPORT_CHECKPOINT`)
        // handleDownloadOnClick(
        //   getValue(resp, `data.downloadUrl`, ""),
        //   "checkpoint"
        // );
        // toast.success('Exported Successfully')
      }
    } catch (error) {}
  };

  const [uploadLoader, setUploadLoader] = useState(false);
  const handleCheckpointImport = async (e: any) => {
    try {
      setUploadLoader(true);
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      let resp = await adminImportCheckpoint(formData);
      if (resp) {
        e.target.value = null;
        toast.success("Imported Successfully");
        if (getValue(resp, `data.downloadUrl`, "")) {
          handleDownloadOnClick(
            getValue(resp, `data.downloadUrl`, ""),
            "Checkpoint Error Report"
          );
        }
        getData();
        setUploadLoader(false);
      } else {
        e.target.value = null;
        setUploadLoader(false);
      }
    } catch (error) {
      e.target.value = null;
      setUploadLoader(false);
    }
  };

  const [migrationLoading, setMigrationLoading] = useState(false);
  const handleMigration = async () => {
    try {
      setMigrationLoading(true);
      let resp = await MigrationToClient();
      if (resp) {
        setMigrationLoading(false);
        toast.success("Migration completed successfully");
      } else {
        setMigrationLoading(false);
      }
    } catch (error) {
      setMigrationLoading(false);
    }
  };
  const getTrProps = (rowInfo: any) => {
    return {};
  };
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "checkpoint_active"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          {getValue(selectedIDs, `length`, 0) > 0 ? (
            <NavigationBar
              selectedIDs={selectedIDs}
              isMarkInactiveSectionVisible={true}
              handleChangeActiveStatus={handleChangeActiveStatus}
              text={"MARK INACTIVE"}
              handleRemoveNavItems={handleRemoveNavItems}
              permissions={props.permissions}
            />
          ) : null}
          <div className="dashboard-wrapper__header">
            <h4 className="dashbaord-wrapper__header-title">Checkpoint</h4>
          </div>

          <div className="admin-wrapper position-relative">
            <div className="admin-wrapper position-relative">
              <SearchHeader
                route={"/checkpoint/active-detail"}
                searchInput={search_text}
                showSearchTextbox={showSearchTextbox}
                toggleSearch={toggleSearch}
                toggleSearchClose={toggleSearchClose}
                handleChangeSearch={handleChangeSearch}
                isBulkUploadVisible={
                  getValue(props, `permissions`, []).includes("update")
                    ? true
                    : false
                }
                smartFilter={true}
                setFilterFormVisibility={() => {
                  setFilterVisibility(!isFilterVisible);
                }}
                isFilterVisible={isFilterVisible}
                placeholder={"Search Checkpoint ID"}
                export={
                  getValue(props, `permissions`, []).includes("update")
                    ? true
                    : false
                }
                handleExport={handleCheckpointExport}
                handleImport={handleCheckpointImport}
                uploadLoader={uploadLoader}
                add={
                  getValue(props, `permissions`, []).includes("create")
                    ? "true"
                    : "false"
                }
                migrate
                migrationLoading={migrationLoading}
                handleMigration={handleMigration}
                commonUpload
                dropdownOptions={dropdownOptions}
                // log_value={"CHECKPOINT"}
                commonViewLogsCheckpoint
                dropdownOptionsCheckpoint={dropdownOptionsCheckpoint}
              />
            </div>
            {isFilterVisible && (
              <FilterForm
                setFilterFormVisibility={() => {
                  setFilterVisibility(!isFilterVisible);
                }}
                countryList={countryList}
                states={states}
                handleChangeSelect={handleChangeSelect}
                country_ID={country_ID}
                state_ID={state_ID}
                reset={reset}
                // smart filter
                handleSubmitSmartFilter={handleSubmitSmartFilter}
                selectedSmartFilterIds={selectedSmartFilterIds}
                handleChangeSelectMulti={handleChangeSelectMulti}
                additionalFilter={true}
                smartFilterList={smartFilterList}
                request={request}
                //dynamic filter ( Custom Input Select Dropdown)
                //----> category of law
                categoryOfLawList={categoryOfLawList}
                getCategoryLawList={getCategoryLawList}
                categoryLawLoading={categoryLawLoading}
                cat_ID={cat_ID}
                //----> legislation
                legislationList={legislationList}
                getLegislationList={getLegislationList}
                legislationLoading={legislationLoading}
                leg_ID={leg_ID}
                //----> legislation rule
                ruleList={ruleList}
                getLegislationRuleList={getLegislationRuleList}
                rule_ID={rule_ID}
                legislationRuleLoading={legislationRuleLoading}
                resetInput={resetInput}
              />
            )}
            {/* <AddFilter
								isFilterVisible={isFilterVisible}
								isFilterHeadingVisible={true}
								isFilterSubHeadingVisible={true}
								isBulkUploadVisible={true}
								addButtonLink={"/checkpoint/active-detail"}
								setFilterFormVisibility={() => {
									setFilterVisibility(!isFilterVisible);
								}}
							/> */}
            {isLoading || !props.permissionAPITriggered ? (
              <Loader />
            ) : list.length > 0 ? (
              <div
                className={
                  getValue(selectedIDs, `length`, 0) > 0
                    ? "scrollable_table_container_nav"
                    : "scrollable_table_container"
                }
              >
                <ScrollableDataTable
                  columns={columns}
                  data={list}
                  getTableState={getTableState}
                  rowClick={rowClick}
                  check={check}
                  selectedIDs={selectedIDs}
                  getTrProps={getTrProps}
                />
              </div>
            ) : (
              <>
                {Object.keys(params).length !== 0 ? (
                  <>
                    <Notfound />
                  </>
                ) : (
                  <Notfound />
                )}
              </>
            )}
          </div>
          <div className="footer-wrapper d-flex justify-content-between">
            {list.length > 0 && (
              <img
                src="/images/building-illustration.svg"
                className="img-fluid"
              />
            )}
            {totalCount > 100 ? (
              <PaginationPage
                totalCount={totalCount}
                limit={limit}
                pageNumber={page_no}
                handleChangePagination={handleChangePagination}
                handleChangeLimit={handleChangeLimit}
              />
            ) : (
              ""
            )}
          </div>
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />
        </>
      )}
    </DashboardLayout>
  );
};

export default CheckpointActive;
