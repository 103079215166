import { getValue } from "@utils/lodash";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import LogSubheaderSvgComponent from "../../assets/svg/log";

function BulkViewLogs(props: any) {
  const navigate = useNavigate();
  const { dropdownOptions, url, type } = props;

  const handleNavigateLogs = (name: string) => {
    // console.log(name, "NAME");
    switch (name) {
      case "EXPORT_CHECKPOINT":
        return navigate(`/export-file-logs/EXPORT_CHECKPOINT`);
        default:
        return navigate(`/file-logs/${name}`);
    }
  };
  return (
    <div className="icon-dropdown-holder ms-0">
      {/* <ReactTooltip id="subHeaderViewLogsDD" effect="solid" place="bottom">
        View Logs
      </ReactTooltip> */}
      <UncontrolledDropdown>
        <DropdownToggle
          className="icons-btn px-0"
          data-tip
          data-for="subHeaderViewLogsDD"
        >
          <div className="add-filter-wrapper__filter-container ms-0">
						<div className="cursor-pointer">
							<img
								// src="/images/file-upload.svg"
								src="/images/taskback.svg"
								className="img-fluid add-filter-wrapper__upload-image"
							/>
							<p className="add-filter-wrapper__filter-title">VIEW LOGS</p>
						</div>
					</div>
        </DropdownToggle>
        <DropdownMenu>
          {getValue(dropdownOptions, `length`, 0) > 0 &&
            dropdownOptions.map((item: object) => {
              // if (getValue(item, `value`, "") !== "SELF_COMPLIANCE_BULK_UPLOAD")
                return (
                  <DropdownItem
                    onClick={() => {
                      handleNavigateLogs(getValue(item, `value`, ""));
                    }}
                  >
                    {getValue(item, `label`, "")}
                  </DropdownItem>
                );
            })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}

export default BulkViewLogs;
